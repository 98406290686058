import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Spinner } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../api/config";
import { getReservationById } from "../api/reservation";
import { useHall } from "../context/HallContext";
import { setReservationID, setAuthToken } from "../utils/auth";
import "../styles/welcome-page.css";

const WelcomePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [reservation, setReservation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { setHall } = useHall();

    // Extract token from URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const reservationId = queryParams.get("reservation_id");
    const hall = queryParams.get("hall");
    setReservationID(reservationId)
    setAuthToken(token)
    console.log("Now token: ", token)


    useEffect(() => {
        if (hall) {
            setHall(hall);
        }
    }, [hall, setHall]);
    useEffect(() => {
        if (!token) {
            setError("Invalid or missing token.");
            setLoading(false);
            return;
        }

        // Fetch reservation data using the token
        const fetchReservation = async () => {
            try {
                console.log(token)
                const response = await axios.get(`${API_URL}/api/reservations/`, {
                    headers: {
                        "ngrok-skip-browser-warning": "69420",
                        Authorization: `Bearer ${token}`,
                    },
                });

                setReservation(response.data);
            } catch (err) {
                setError("Failed to fetch reservation details. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchReservation();
    }, [token]);

    const handleContinue = () => {
        if (reservation) {
            // Navigate to the next step, e.g., a menu selection page
            navigate("/subscription", { state: { reservationId } });
        }
    };

    if (loading) {
        return (
            <div className="welcome-page">
                <Container>
                    <Row className="justify-content-center">
                        <Spinner>Loading...</Spinner>
                    </Row>
                </Container>
            </div>
        );
    }

    if (error) {
        return (
            <div className="welcome-container">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="8" md="10" className="text-center">
                            <p className="error-text">
                                Oops! It seems the link you used has expired or is invalid.
                            </p>
                            <p className="contact-text">
                                Please contact our office at <strong>info@excellencymidlands.com </strong>
                                or call us at <strong>0121 306 0231</strong> for assistance.
                            </p>
                            <p className="contact-text">
                                We’re here to help ensure your reservation is handled smoothly.
                            </p>
                            <Button
                                onClick={() => navigate("/contact")}
                                className="contact-btn"
                            >
                                Contact Us
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }


    return (
        <div className="welcome-page">
            <Container>
                <Row>
                    <Col>
                        <div className="welcome-container">
                            <h2 className="welcome-title">Welcome to Excellency Midlands</h2>
                            <p className="welcome-text">
                                Congratulations on your upcoming celebration. We are honored to be part of your special day.
                            </p>
                            <p className="welcome-text">
                                As you begin to select your menu, you’ll be choosing from an array of exquisite dishes, each crafted by our team of master chefs, dedicated to the art of fine dining halal cuisine. Every ingredient, every flavor, and every presentation is carefully curated to embody elegance and perfection, creating a memorable experience for you and your guests.
                            </p>
                            <p className="welcome-text">
                                Please take your time exploring our selections, knowing each choice reflects our highest standards of quality and sophistication. If there is anything we can assist you with, do not hesitate to reach out.
                            </p>
                            <p className="welcome-text">
                                We look forward to creating a dining experience that will be remembered for a lifetime.
                            </p>
                            <Button onClick={handleContinue} className="submit-btn">Let's start</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default WelcomePage;
