import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/progresstracker.css"; // Import the CSS for additional styling

const ProgressTracker = ({ currentStep, totalSteps, stepNames, stepsWithPaths }) => {
    const navigate = useNavigate();

    const steps = Array.from({ length: totalSteps }, (_, index) => index + 1);

    const handleStepClick = (step) => {
        if (step <= currentStep) {
            const stepPath = stepsWithPaths.find((item) => item.step === step)?.path;
            if (stepPath) {
                navigate(stepPath, { state: { step } });
            }
        }
    };


    return (
        <div className="stepper-wrapper">
            {steps.map((step, index) => {
                const isActive = currentStep === step;
                const isCompleted = currentStep > step;
                const isClickable = step <= currentStep;

                return (
                    <div
                        key={step}
                        className={`stepper-item ${isCompleted ? "completed" : ""} ${isActive ? "active" : ""} ${isClickable ? "clickable" : ""
                            }`}
                        onClick={() => handleStepClick(step)}
                        style={{ cursor: isClickable ? "pointer" : "not-allowed" }}
                    >
                        <div className="step-counter">{step}</div>
                        <div className="step-name">{stepNames[step - 1]}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default ProgressTracker;
