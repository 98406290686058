import React, { createContext, useState, useContext } from "react";

const HallContext = createContext();

export const HallProvider = ({ children }) => {
    const [hall, setHall] = useState(null);

    return (
        <HallContext.Provider value={{ hall, setHall }}>
            {children}
        </HallContext.Provider>
    );
};

export const useHall = () => useContext(HallContext);
