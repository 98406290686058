import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getReservationById, deleteReservation } from '../api/reservation';
import { getDishById } from '../api/dishes';
import { getAuthToken } from "../utils/auth";
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';

const ReservationDetailsPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [reservation, setReservation] = useState(null);
    const [dishData, setDishData] = useState([]);
    const token = getAuthToken();

    useEffect(() => {
        const fetchReservation = async () => {
            try {
                const data = await getReservationById(id, token);
                const dishDetailsPromises = data.dishes.map(dishId => getDishById(dishId, token));
                const dishDetails = await Promise.all(dishDetailsPromises);

                setDishData(dishDetails);
                setReservation(data);
            } catch (error) {
                console.error("Error fetching reservation:", error);
            }
        };
        fetchReservation();
    }, [id]);

    const handlePrint = () => {
        window.print();
    };

    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this reservation?")) {
            try {
                await deleteReservation(id, token);
                alert("Reservation deleted successfully!");
                navigate("/admin"); // Redirect to the reservations list page
            } catch (error) {
                console.error("Error deleting reservation:", error);
                alert("Failed to delete the reservation. Please try again.");
            }
        }
    };

    const groupDishesByCategory = (dishes) => {
        return dishes.reduce((acc, dish) => {
            const { category, title } = dish;
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(title);
            return acc;
        }, {});
    };

    const groupedDishes = groupDishesByCategory(dishData);

    if (!reservation) return <p>Loading...</p>;

    return (
        <Container className="my-5">
            <style>
                {`
                @media print {
                    body * {
                        visibility: hidden;
                    }
                    #printable-area, #printable-area * {
                        visibility: visible;
                    }
                    #printable-area {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                    }
                    input, .no-print {
                        display: none !important;
                    }
                    p {
                        font-weight: bold;
                        font-size: 18px; /* Make text larger for print */
                    }
                    #printable-area::before {
                        content: '';
                        background: url('../assets/images/Excellency-header-logo.png') no-repeat center;
                        background-size: 50%; /* Adjust size as needed */
                        opacity: 0.1; /* Light watermark effect */
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: -1; /* Behind content */
                    }
                }
                `}
            </style>
            <Card className="shadow-sm">
                <CardBody>
                    <div id="printable-area">
                        <h2 className="text-center mb-4"><strong>Menu Details</strong></h2>
                        <div className="d-flex justify-content-center mb-4">
                            <h5 className="mx-3"><strong>{new Date(reservation.date).toLocaleDateString()}</strong></h5>
                            <h5 className="mx-3"><strong>{reservation.time}</strong></h5>
                            <h5 className="mx-3"><strong>{reservation.number_of_people} guests</strong></h5>
                        </div>

                        <Row className="mb-3">
                            <Col>
                                <p><strong>Hall:</strong> {reservation.hall}</p>
                            </Col>
                            <Col>
                                <p><strong>Client Name:</strong> {reservation.client_name}</p>
                            </Col>
                            <Col>
                                <p><strong>Cuisine:</strong> {reservation.cuisine}</p>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <p><strong>Spice Level:</strong> {reservation.spice_level}</p>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <p><strong>Dishes:</strong></p>
                                {Object.keys(groupedDishes).map((category) => (
                                    <p key={category}><strong>{category}:</strong> {groupedDishes[category].join(", ")}</p>
                                ))}
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <p><strong>Extra Notes:</strong> {reservation.additional_notes}</p>
                            </Col>
                        </Row>
                    </div>

                    {/* Buttons */}
                    <Row className="mt-4 no-print">
                        <Col className="text-center">
                            <Button color="primary" onClick={handlePrint} className="mx-2">Print Reservation</Button>
                            <Button color="danger" onClick={handleDelete} className="mx-2">Delete Reservation</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
    );
};

export default ReservationDetailsPage;
