import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import { useHall } from "../../context/HallContext";
import { NavLink } from "react-router-dom";
import defaultLogo from "../../assets/images/Excellency-header-logo.png";
import jomairehLogo from "../../assets/images/balmayna.webp";
import "../../styles/header.css";

const Header = () => {
  const menuRef = useRef(null);
  const headerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { hall } = useHall();
  console.log("Hall: ", hall)
  const [menuOpen, setMenuOpen] = useState(false);
  const logo = (hall === "Jomaireh" || hall === "Palm") ? jomairehLogo : defaultLogo;





  const nav__links = [
    {
      display: "Home",
      path: "/home",
    },
    {
      display: "Chefs",
      path: "/chefs",
    },
  ];

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
    menuRef.current.classList.toggle("show__menu");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        headerRef.current.classList.add("header__shrink");
      } else {
        headerRef.current.classList.remove("header__shrink");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header className="header" ref={headerRef}>
      <Container>
        <div className="nav__wrapper d-flex align-items-center justify-content-between">
          {/* Logo */}
          <div className="logo" onClick={() => navigate("/home")}>
            <img
              src={logo}
              alt="logo"
            />
          </div>

          {/* Navigation Menu */}
          <div className="navigation" ref={menuRef} onClick={toggleMenu}>
            <div
              className="menu d-flex align-items-center gap-5"
              onClick={(event) => event.stopPropagation()}
            >
              {nav__links.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className={(navClass) =>
                    navClass.isActive
                      ? "active__menu nav__link-item"
                      : "menu_item nav__link-item"
                  }
                  onClick={toggleMenu}
                >
                  {item.display}
                </NavLink>
              ))}
            </div>
          </div>

          {/* Mobile Menu Toggle */}
          <span className="mobile__menu" onClick={toggleMenu}>
            <i className={menuOpen ? "ri-close-fill" : "ri-menu-line"}></i>
          </span>
        </div>
      </Container>
    </header>
  );
};

export default Header;
